import { toast } from '@/components/ui/use-toast';

export const AWS_COGNITO_RESPONSE_TYPE = 'code';
export const AWS_COGNITO_SCOPE = 'email openid profile';

export const refreshToken = async (
  apiUrl: string,
  onLoggedIn: () => void = () => {},
  onLoggedOut: () => void = () => {},
) => {
  if (
    window.location.pathname === '/callback' ||
    window.location.pathname === '/callback/'
  ) {
    return;
  }

  const response = await fetch(
    new URL('/auth/refresh_token', apiUrl).toString(),
    {
      credentials: 'include',
    },
  );

  if (response.ok) {
    onLoggedIn();
  } else if (response.status === 401) {
    onLoggedOut();
  } else {
    const errorMessage = `An authentication error occurred: ${response.status} ${response.statusText}`;
    toast({
      title: 'Error',
      description: errorMessage,
      variant: 'destructive',
    });
    onLoggedOut();
    throw new Error(errorMessage);
  }
};
