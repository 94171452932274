type CacheItem = {
  promisedValue: Promise<any>;
  expiration: number;
};

export default class PromisedCache {
  private cache: Record<string, CacheItem>;

  constructor() {
    this.cache = {};

    setInterval(() => {
      const now = Date.now();
      Object.entries(this.cache).forEach(([key, item]) => {
        if (item.expiration < now) {
          this.delete(key);
        }
      });
    }, 1000);
  }

  get(key: string): Promise<any> | null {
    const item = this.cache[key];
    if (item) {
      if (item.expiration > Date.now()) {
        return item.promisedValue;
      }
      this.delete(key);
    }

    return null;
  }

  setValue(key: string, ttlSeconds: number, value: any): void {
    this.setPromise(key, ttlSeconds, Promise.resolve(value));
  }

  setPromise(
    key: string,
    ttlSeconds: number,
    promisedValue: Promise<any>,
  ): void {
    this.cache[key] = {
      promisedValue,
      expiration: Date.now() + ttlSeconds * 1000,
    };
  }

  delete(key: string): void {
    delete this.cache[key];
  }
}
