import posthog from 'posthog-js';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  isLoggedIn: boolean;
  setLoggedOut: () => void;
  setLoggedIn: () => void;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isLoggedIn: false,

      setLoggedIn: () => {
        set((state) => {
          if (!state.isLoggedIn) {
            return { ...state, isLoggedIn: true };
          }
          return state;
        });
      },
      setLoggedOut: () => {
        set((state) => {
          if (state.isLoggedIn) {
            return { ...state, isLoggedIn: false };
          }
          posthog.reset();
          return state;
        });
      },
    }),
    {
      name: 'nullify-dashboard-auth',
    },
  ),
);

export default useAuthStore;
